<template>
<div id= "goalmanagepage">

<div id="goalmanage">

<div id="goalshow">

  <div class="goalshowheaderzone">

   
  <div class="projects-section-line">
        
    <div class="projects-status">

          <div class="item-status itemforward" @click='filtergoals("unfinished")' >
            <span class="status-number">{{inprogressnumber}}</span>
            <span title="正在进行中"   class="status-type"><timericon style="width:13px;margin-top:0px"/></span>
          </div>
          <div class="item-status itemforward"  @click='filtergoals("finished")'>
            <span class="status-number">{{finishedgoalnumber}}</span>
            <span class="status-type"><doneicon/></span>
          </div>
          <div class="item-status itemforward"  @click='filtergoals("giveup")'>
            <span class="status-number">{{giveupgoalnumber}}</span>
            <span class="status-type"><giveupicon/></span>
          </div>
          <div class="item-status "  @click='filtergoals("total")'>
            <span class="status-number">{{totalgoalnumber}}</span>
            <span class="status-type">Total</span>
          </div>
        </div>
    
        <div class="goaltextinbox">你的目标</div>
</div>
 <h1><a class="goalpagetitle"></a><button class="buttontitle" title="创建你的新目标" id="addgoalbutton" @click="showaddgoalzone_func" > 
  +</button></h1>
  <div id="back">
  <a class="backbutton" href="/taskmanager">{{symbol}}任务面板</a>  
</div>
  </div>

<div id="goalpartbody"   class="goalpartbody">

  <div class="goalblockzone">
<div v-for="item in items" :key="item.id"  @click="showeditgoalzone_func(item)">
 <div class="project-box-wrapper" >
      <div class="project-box" :class="'blockstatus_'+item.goalstatus" >
        <div class="project-box-header">
          <span :class="'goalstatus_'+item.goalstatus"><div class="goalnametext"> {{item.name}}</div></span>
          
          <div class="more-wrapper">
            <button class="project-btn-more">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical">
                <circle cx="12" cy="12" r="1"></circle>
                <circle cx="12" cy="5" r="1"></circle>
                <circle cx="12" cy="19" r="1"></circle></svg>
            </button>
      </div>
    </div>
    <div class="project-box-content-header">
      <p class="box-content-header">
      <span class="infocaption">目标权重</span> {{item.priority}}</p>
      <div class="box-content-subheader">
        <span class="infocaption">目标状态</span>
        <div v-if="item.goalstatus=='finished'"><doneicon /></div>
        <div v-else-if="item.goalstatus=='giveup'"><giveupicon />
        </div>
          <div v-else title="正在进行中"><timericon style="width:14px;margin-top:0px" />
        </div>
      </div>
      <div class="box-content-subheader">
        <span class="infocaption">目标别名</span> 
        <div v-if="item.alias=='unspecified'">未指定</div>
        <div v-else>{{item.alias}}</div>
      </div>
    </div>
   
    <div v-show="false"   class="project-box-footer">
      <div class="participants">
        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=2550&amp;q=80" alt="participant">
        <img src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTB8fG1hbnxlbnwwfHwwfA%3D%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=900&amp;q=60" alt="participant">
        <button class="add-participant" style="color: #ff942e;">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus">
            <path d="M12 5v14M5 12h14"></path>
          </svg>
        </button>
      </div>
      <div class="days-left" style="color: #ff942e;">
        2 Days Left
      </div>
    </div>
    <div>
      <!-- <p class="box-progress-header">progress</p> -->
      <div class="box-progress-bar">
        <span class="box-progress" ></span>
      </div>
      <!-- <p class="box-progress-percentage">unkown</p> -->
    </div>
  </div>
</div>
</div>

</div>
<div class="goalactionzone">
<div id="addgoal" v-show="showaddgoalzone">
  <div class="closebutton" @click="closebox"><close class="closesvg"/></div>
  <h1 class="goaltitle">添加目标</h1>
  <div class="namezone">
     <span>目标名称</span>    
     <input placeholder="请输入你的目标" v-model="goalcontent" >
  </div>
  <div class="namezone">
     <span>目标别名</span>    
     <input placeholder="输入目标的代替显示名" v-model="goalalias" >
  </div>
  <div class="namezone">
    <span>目标权重</span>  
      <a-select
      ref="select"
      v-model:value="selectedpriority"
      style="width: 120px"
      :options="options1"
      @focus="focus"
      @change="handleChange"
    ></a-select>

  </div>

   <button  class = "mybutton" @click="creategoal">创建目标</button>
   <!-- <a-button type="primary"  class = "mybutton" @click="creategoal">Create</a-button> -->
</div>

<div id="editgoal" v-show="showeditgoalzone">
  <div class="closebutton" @click="closebox"><close class="closesvg"/></div>
  <h1 class="goaltitle">编辑目标</h1>
   <div class="namezone">
     <span>目标名</span>
     <input placeholder="请输入你的目标" v-model="goalcontent" >
  </div>
  <div class="namezone">
     <span>目标别名</span>    
     <input placeholder="输入目标的代替显示名" v-model="goalalias">
  
  </div>
  <div class="namezone">
    <span>目标状态</span>
    <a-select
      ref="select"
      v-model:value="selectedstatus"
      style="width: 120px"
      :options="options2"
      @focus="focus"
      @change="handleChange"
    ></a-select>
  </div>
 
  
  <!-- <button class = "mybutton" @click="modifygoal">修改目标</button> -->
  <a-button  class = "mybutton" @click="modifygoal">提交修改</a-button>
</div>
</div>

</div>




</div>


<!-- <div id="editgoal">

</div> -->
</div>

</div>
</template>

<script>
import axios from 'axios'
import configure_options from '../configure'
import { defineComponent,ref } from 'vue';
import close from '../svg/close.vue'
import doneicon from '../svg/done.vue'
import giveupicon from '../svg/giveupword.vue'
import timericon from '../svg/timericon.vue'
// const axios = require('axios').default;
export default defineComponent({
  name: 'goalshow',
  props: {
    msg: String,
    
  },
  components:{
  doneicon,
  giveupicon ,
  timericon,
  close},
  // data() {
  //     return {
  //      items:[],
    
  //     }

  // },
  methods:{
    sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
    },


    closebox(){
// this.showboxcontainer = false
    this.showaddgoalzone = false;
    this.showeditgoalzone= false;
    // alert("1")
    document.getElementById("goalpartbody").style.width="100vw";
    document.getElementById("goalpartbody").style.maxWidth="100vw"
  },

modifygoal(){
          // alert("检查海曙是否调用")
          // var _this = this
          console.log(this.currentgoal)
          if(this.currentgoal==null){
            alert("服务异常,请联系管理员")
            return
          }
          this.showeditgoalzone = false
          var env =  configure_options["env"]
          console.log("目前的目标是这样的")
          console.log(this.currentgoal)
            console.log(configure_options["app_setting"][env])
            var serverhost =configure_options["app_setting"][env]["serverhost"]
            var goalstatus ="unspecified"
            if(this.selectedstatus =="已经完成"){
               goalstatus = "f"
              //  alert(this.currentgoal.goalcode)
            }
            if(this.selectedstatus =="已经放弃"){
               goalstatus = "g"
              //  alert(this.currentgoal.goalcode)
            }
            if(this.selectedstatus =="正在进行中"){
               goalstatus = "doing"
              //  alert(this.currentgoal.goalcode)
            }
            if(this.goalalias == "未指定"){
              this.goalalias =="unspecified"
            }
            var goal  ={"goalcode":this.currentgoal.goalcode,alias:this.goalalias,goal:this.goalcontent,"goalstatus":goalstatus,finishtime:"unspecified",priority:-1}
            var _this = this
            axios.post(serverhost + '/v1/updategoal',goal)
             .then(response => {
              
              console.log(response.data)
              _this.getgoals()
            }) .catch(error=>{
              if(error.response.status==912){
                alert("当前目标还有未标记为(放弃或完成的)任务与目标")
              }else{
                console.log(error)
              alert("服务异常，请稍后再试")
              }
           
            
            })
              
          

        },


      filtergoals(goalstatus){
           var finalitems = []
           if(goalstatus=="total"){
            this.items=this.originitems
            return
           }else{
            for(var i=0;i<this.originitems.length;i++) 
           if(this.originitems[i].goalstatus==goalstatus){
               finalitems.push(this.originitems[i])
           }
           this.items=finalitems
          return
           }
        
          }

  },
  setup(props,context) {
  // const value1 = ref(500);
  
    const handleChange = value => {
      console.log(`selected ${value}`);
      context.emit("testchild")
    };

    var  options1 =  [{
                        id:0,
                        value:1,  
                      },{
                        id:1,
                        value:2
                      },{
                        id:2,
                        value:3
                      },
                      {
                        id:3,
                        value:4
                      },
                      {
                        id:4,
                        value:5
                      },
                      
                      ]



      var  options2 =  [{
                        id:0,
                        value:"已经放弃",  
                      },{
                        id:1,
                        value:"已经完成"
                      },{
                        id:2,
                        value:"正在进行中"
                      },
                      
                      ]


     var currentgoal = null
    

      async  function creategoal(){
              var env =  configure_options["env"]
            
              console.log(configure_options["app_setting"][env])
              var serverhost =configure_options["app_setting"][env]["serverhost"]
         
            // alert("穆阿标点击")
          // alert(this.selectedpriority)
        
            if(this.goalcontent==""){
              alert("目标不能为空")
              return
            }

            var goal  ={"goal":this.goalcontent,"priority":this.selectedpriority,"alias":this.goalalias}
            axios.post(serverhost + '/v1/creategoal',goal)
             .then(response => {
              console.log(response.data)
              //可以将状态传入到父亲组件
            //    document.getElementById("addtaskinprojectzone").style.display="none"
            //  document.getElementById("goaltreedatapart").style.opacity=1
            //  document.getElementById("goaltreedatapart").style.pointerEvents="auto"
            //  document.getElementById("top").style.opacity=1
            //    emitter.emit("refresh_goaltree","createtaskinproject_component_ask")
            // 要让左侧进行对应的刷新
            this.showaddgoalzone = false
            this.getgoals()

          })
          .catch(error=>{
            console.log(error)
        
          if(error.response.status==902){
              alert("不能使用相同的目标名")
          }else{
            alert("服务器出错")
          }})
            return 
        }

      function showaddgoalzone_func(){
        this.showaddgoalzone = true
        this.showeditgoalzone = false
        document.getElementById("goalpartbody").style.width="50vw";
         document.getElementById("goalpartbody").style.maxWidth="50vw"
      }

      function showeditgoalzone_func(goal){
        document.getElementById("goalpartbody").style.width="50vw";
         document.getElementById("goalpartbody").style.maxWidth="50vw"
        this.currentgoal = goal
        // alert(this.currentgoal)
        this.showeditgoalzone = true
        this.showaddgoalzone = false
        this.goalcontent = goal.name
        this.goalalias=goal.alias
      if(goal.alias=="unspecified"){
        this.goalalias = "未指定"
      }
        //  this.selectedstatus = goal.goalstatus
        if(goal.goalstatus=="finished"){
          this.selectedstatus ="已经完成"
        }
        if(goal.goalstatus=="unfinished" ||goal.goalstatus=="" ){
          this.selectedstatus ="正在进行中"
        }
          if(goal.goalstatus=="giveup"  ){
          this.selectedstatus ="已经放弃"
        }

        if(goal.goalalias=="unspecified"  ){
          this.goalalias ="未指定"
        }


        console.log(goal)
      }



     function   getgoals(){
        var env =  configure_options["env"]
          console.log("目前的目标是这样的")
          console.log(Date.now())
          console.log(this.currentgoal)
            console.log(configure_options["app_setting"][env])
            var serverhost =configure_options["app_setting"][env]["serverhost"]
            var _this= this
            axios.get(serverhost+"/v1/goaljson").then(
          response=>{

            // alert("该函数已经被调用")
            var data = response.data
            console.log("~~~~~~~~~~~~~~~我正在打印所有的目标~~~~~~~~~")
            console.log(data);
            _this.items = data.goals
            _this.originitems = data.goals
            // 循环处理
            var finalfinishedcount = 0
            var finalgiveupcount =0 
            var inprogresscount = 0
            for(var i=0;i<data.goals.length;i++){
              if(data.goals[i].goalstatus=="finished"){
                finalfinishedcount= finalfinishedcount+1
                console.log(data.goals[i].name)
                console.log("finished")
              }
              if(data.goals[i].goalstatus=="giveup"){
                finalgiveupcount= finalgiveupcount+1
              }
              
              if(data.goals[i].goalstatus=="unfinished" ||data.goals[i].goalstatus=="" ){
                inprogresscount  = inprogresscount  +1
        }
            }
            _this.finishedgoalnumber = finalfinishedcount
            _this.giveupgoalnumber = finalgiveupcount
            _this.inprogressnumber = inprogresscount
            _this.totalgoalnumber = data.goals.length
            console.log("检查手否调用")
            console.log(inprogresscount,finalfinishedcount, finalgiveupcount,data.goals.length )
            console.log( _this.inprogressnumber,_this.finishedgoalnumber,_this.giveupgoalnumber ,_this.totalgoalnumber )
            // console.log(status);
            // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
          }
   ).catch(error=>{console.log(error)})
      }


  

    return {
      currentgoal,
      items:ref([]),
      symbol:"<",
      finishedgoalnumber:ref(0),
      giveupgoalnumber:ref(0),
      inprogressnumber:ref(0),
      totalgoalnumber:ref(0),
      goalcontent:ref(""),
      goalalias:ref("未指定"),
      originitems:ref([]),
      showaddgoalzone:ref(false),
      showeditgoalzone:ref(false),
      creategoal,
      showaddgoalzone_func,
      showeditgoalzone_func,
      getgoals,
     
      handleChange,
      selectedpriority:ref(0),
      selectedstatus:ref("未完成"),
      options1,
      options2,
      
   
    };
  },
  mounted(){
     
  

  this.getgoals()
    
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

#goalmanagepage {
  display: flex;
  flex-direction:row;
  height: 100%;
  overflow:auto;
  background-color: rgb(222, 235, 251);
}

#back{
  /* position: absolute;; */
  right: 0%;
  width: 200px;
  margin-left: auto;
  // position: fixed;

  /* display:block; */

  
.backbutton{
  height: 45px;
    line-height: 45px;
    width: 150px;
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;
    border: 1px solid #181828;
    // border-radius: 5%;
    color: #181828;
    text-decoration: none;
    background: snow;
    font-family: "handwriting1";
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: .1rem;
  
    border-radius:5px;
    overflow: hidden;
    text-align: center;

    &:hover{
      background: rgb(184, 115, 115);
      color:white;
    }
}

}

.goalblockzone{
  display: flex;
  flex-wrap: wrap;
  margin-left:30px;


  .goalnametext{
    font-weight: 800;
  }
}


#goal{
    display:block;
}

#goalmanage{
  display: flex;
  justify-content: space-between; 
  margin-right: 100px;
}

.goalactionzone{
  left:50%;
  position:absolute;
  width:10vw;

  
}



.goalpartbody{
  display: flex;
  flex-direction:row;
}

#addgoalbutton{
  border: none;
  background: none;

}


// .goalstatus_finished::after{
//   color: red;
//   content:" 已经完成了";
//   font-family: math;
// }

// .goalstatus_giveup::after{
//   color:rgb(129, 13, 238);
//   content: " 放弃了x";
//   font-family: math;
// }

#goalshow{

  text-align: left;
  // margin-left: 50px;
  // margin-right: 200px;
  max-width:100vw;
  width: 100vw;
  font-family: math;
 
  /* position:absolute; */
  /* left: 40%; */
}
#addgoal{
  display: flex;
  flex-direction: column;
  // position:fixed;
  z-index:999;
  width:40vw;
  border: #915353 dashed 1px;
  align-items: flex-start;
  // margin-right: 100px;
  padding: 20px;
}


#editgoal{
  display: flex;
  flex-direction: column;
  /* width: 70%; */
  // position:fixed;
  width:40vw;
  align-items: flex-start;
  border: #915353 dashed 1px;
  // margin-right: 100px;
  padding: 20px;
}

.goaltitle{
  text-align: left;
  font-size: 25px;
}

.projects-section-line{
  line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    font-family: "DM Sans", sans-serif;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
    // padding-bottom: 32px;


}




.item-status {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    padding-right: 20px;
    padding-left: 20px;
    
}


.itemforward{
  border-right: 1px solid #dee2e6;
}

.status-type {
    // position: relative;
    // width:23px;
    // height: 23px;
    color: black;
}

.namezone{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top:5px;
}

.buttontitle{
  font-size: 20px;
}

.mybutton{
  border-radius: 15px;
  margin-top: 12px;
  background-color: white;
  color: blue;
  max-width: 200px;
}

.mybutton:hover{
  background-color:rgb(240, 228, 228);
  align-items: flex-end;
}


.box-progress-header{
  -webkit-text-size-adjust: 100%;
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    font-family: "DM Sans", sans-serif;
    --main-color-card: #dbf6fd;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin: 0;
}

.project-box-wrapper{

  line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    font-family: "DM Sans", sans-serif;
    box-sizing: border-box;
    padding: 8px;
    transition: 0.2s;
    width: 22vw;


}


.project-box.blockstatus_finished{
  background:#c8f7dc;
}


.project-box.blockstatus_unfinished{
  background:#c3cff4;;
}


.project-box.blockstatus_giveup{
  background:#ffd3e2;
}


.project-box{
  line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    font-family: "DM Sans", sans-serif;
    box-sizing: border-box;
    --main-color-card: #dbf6fd;
    border-radius: 30px;
    padding: 16px;
    background-color: #fee4cb;
}


.project-box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    color: var(--main-color);
}


.box-content-header {
  margin-bottom: 1px;
}

.project-box-content-header {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --antd-wave-shadow-color: #1890ff;
    --scroll-bar: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    -webkit-font-smoothing: antialiased;
    color: #2c3e50;
    text-align: left;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    font-family: "DM Sans", sans-serif;
    --main-color-card: #dbf6fd;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 1px;
}

.project-box-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    position: relative;
}
.box-progress-wrapper{

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --antd-wave-shadow-color: #1890ff;
    --scroll-bar: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    -webkit-font-smoothing: antialiased;
    color: #2c3e50;
    text-align: left;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    font-family: "DM Sans", sans-serif;
    --main-color-card: #dbf6fd;
    box-sizing: border-box;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;
    margin: 8px 0;

}

.box-progress-bar {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --antd-wave-shadow-color: #1890ff;
    --scroll-bar: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    -webkit-font-smoothing: antialiased;
    color: #2c3e50;
    text-align: left;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    font-family: "DM Sans", sans-serif;
    --main-color-card: #dbf6fd;
    box-sizing: border-box;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;
    margin: 8px 0;
}

.box-progress {
  line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    font-family: "DM Sans", sans-serif;
    --main-color-card: #dbf6fd;
    box-sizing: border-box;
    display: block;
    height: 4px;
    border-radius: 6px;
    width: 100%;
    background-color: rgb(179, 70, 70);
}



.participants img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    -o-object-fit: cover;
    object-fit: cover;
}


.infocaption{
  // color: red;
  margin-right:3px;
}
.box-content-subheader{
  display:flex;
 

}



.box-progress-percentage {
    text-align: right;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}
.project-btn-more{
  -webkit-text-size-adjust: 100%;
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    --main-color-card: #dbf6fd;
    box-sizing: border-box;
    overflow: visible;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
    // position: relative;
    background-color: transparent;
    border: none;
    flex-shrink: 0;
    font-family: "DM Sans", sans-serif;
}
.participants {
    display: flex;
    align-items: center;
}
.add-participant{
  -webkit-text-size-adjust: 100%;
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    --main-color-card: #dbf6fd;
    box-sizing: border-box;
    overflow: visible;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    background-color: rgba(255, 255, 255, 0.6);
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-family: "DM Sans", sans-serif;
    color: #df3670;
}





.closebutton{
  text-align: right;
  // margin-right:10px;
  background: none;
  margin-left: auto;
  .closesvg{
    margin-right:10px;
  }
}

.goalshowheaderzone{
  display: flex;
  flex-direction: row;
  // justify-content:space-between;
  align-items: flex-start;



  h1{
    margin-bottom:0px;
    display: flex;
    flex-direction:row;
    
  .goalpagetitle{
    font-size: 48px;
    line-height: 1.08349;
    font-weight: 600;
    letter-spacing: -.003em;
    font-family: SF Pro SC,SF Pro Display,SF Pro Icons,AOS Icons,PingFang SC,Helvetica Neue,Helvetica,Arial,sans-serif;
    display: inline;
     color:red;


}
  }


  .projects-section-line{
    display:flex;
    flex-direction:column-reverse;
    border:2px solid #3a73ad;
    border-radius: 10px;
  

    .goaltextinbox{
      margin-left:auto;
      // position:absolute;
      left:2px;
      font-family: handwriting1;
      background:#dd8724;
      color:white;
    }
  }


  .projects-status {
    display: flex;
   


    .status-number {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: var(--main-color);
}




}
}



</style>
